body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#topSection {
  height: 100vh;
  background-image: url("./Images/shavings.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#bottomSection {
  background-color: white;
  min-height: 20vh;
  padding: 20px 0;
}

#whatWeDoSection {
  padding: 40px 20px;
  background-color: white;
  text-align: center;
}

#whatWeDoSection h2 {
  font-family: 'Mitr', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#whatWeDoSection p {
  font-family: 'Mitr', sans-serif;
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 800px; /* Adjust the max-width as needed */
}
#whatWeDoSection .small-carousel {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
/* Ensure carousel buttons are accessible */
.carousel-control-prev,
.carousel-control-next {
  z-index: 1;
}

#AboutUsSection {
  padding: 40px 20px;
  background-color: white;
  text-align: center;
}

#AboutUsSection h2 {
  font-family: 'Mitr', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#AboutUsSection p {
  font-family: 'Mitr', sans-serif;
  font-size: 1.25rem;
  margin: 0 auto;
  max-width: 800px; /* Adjust the max-width as needed */
}

@media (max-width: 768px){
  #slogan {
    margin-top: 3rem !important;
    font-size: 3rem !important;
  }

}

@media (max-width: 768px) {
  #memo {
    margin-top: 0rem !important; /* Adjust margin for smaller screens */
    font-size: 1.5rem !important; /* Adjust font size for smaller screens */
  }

  #testimonials {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }

  #pictureContainer {
    margin-top: 1rem; /* Adjust margin for smaller screens */
  }
}

#memo {
  color: black;
  font-size: 2.5rem;
  font-family: 'Mitr', sans-serif;
  
}

#slogan {
  font-family: 'Tangerine', cursive;
  margin-top: -6rem;
  font-size: 4rem;
  background-color: rgba(128, 128, 128, 0.0); /* Adjust the alpha (last) value for transparency */
}

#testimonials {
  color: black;
  font-size: 2rem;
  font-family: 'Mitr', sans-serif;
  margin-top: 2rem;
}

.arrow {
  margin-top: 2rem;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Override Bootstrap's default .row styles */
.row {
  margin-left: 0;
  margin-right: 0;
}

.carousel-item {
  transition: opacity 3s ease !important;; /* Adjust the duration as needed */
}
.carousel-item.active { 
  opacity: 1 !important;;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right,
.carousel-item-next.active,
.carousel-item-prev.active {
  opacity: 0 !important;
}


#pictureContainer {
  margin-top: 20px;
  text-align: center;
  width: 40%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#rPic, #uPic {
  width: 100%;
}

#greetingcardMain {
  margin-top: 1rem;
  background-color: transparent;
}

#mainContainerLayout {
  width: 80%;
}

.container-fluid {
  width: 100%;
 
}

a {
  color: black;
  text-decoration: none;
}
