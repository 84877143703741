.footer {
    background-color: rgba(0, 0, 0, 0.8); /* Light black background */
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  